import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as moment from 'moment';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { UserService } from 'src/app/shared/services/user.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss']
})
export class EventInfoComponent implements OnInit {

  @Input() modalOptions: any = null;
  @Input() manageEvents: boolean = true;   
  @Input() eventSelected: any = null;
  @Input() countActivities: number = 0;
  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  private hours = [
    { value: 12 },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
    { value: 11 },
  ];

  private minutes = [
    { value: 0, label: "00" },
    { value: 5, label: "05" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
    { value: 35, label: "35" },
    { value: 40, label: "40" },
    { value: 45, label: "45" },
    { value: 50, label: "50" },
    { value: 55, label: "55" },
    

  ];

  private momentDay = [
    { value: "AM" },
    { value: "PM" }
  ];

  public minutesStart = [
    { value: 0, label: "00" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" },
    { value: 50, label: "50" },
    { value: 60, label: "00" },

  ];

  public hoursStart = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
    { value: 11 },
    { value: 12 },
  ];

  public momentdayStart = [
    { value: "AM" },
    { value: "PM" }
  ];

  public hourSelectedStart: any = moment().format("h");
  public auxhourSelectedStart: any = moment().format("h");

  public minutesSelectedStart: any = (Math.round((parseInt(moment().format("mm")) / 5) + (0.5)) * 5) == 60 ? 0 : (Math.round((parseInt(moment().format("mm")) / 5) + (0.5)) * 5);
  public auxminutesSelectedStart: any = (Math.round((parseInt(moment().format("mm")) / 5) + (0.5)) * 5) == 60 ? 0 : (Math.round((parseInt(moment().format("mm")) / 5) + (0.5)) * 5);

  public momentDaySelectedStart: any = moment().format('A');
  public auxmomentDaySelectedStart: any = moment().format('A');

  public blockEditing: boolean = false;

  public eventInfoForm: FormGroup;
  public dateOptions: any = {};
  public selectedDate: any = { startDate: moment(), endDate: moment() };
  public auxDateSelected: any = { startDate: moment(), endDate: moment() };
  public today = moment();
  public dateRangeFired = false;
  public regex = /((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/;
  public scheduleId = null;
  public validRequired:boolean = false;
  public jsonLanguage: any = {};
  public config: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '328px',
    minHeight: '4rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        'subscript',
        'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        // 'heading',
        'textColor',

        // 'fontName'
      ],
      [
        // 'fontSize',
        // 'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  };

  constructor(
    private fb: FormBuilder,
    public fieldisValid: ValidateFormsDirective,
    public validatorsService: ValidatorsService,
    public userService : UserService
  ) {}

  ngOnChanges(changes: SimpleChanges){
    
    if(!changes?.eventSelected?.firstChange){
       this.blockEditing = changes.eventSelected?.currentValue?.status == 'ACTIVE' && this.modalOptions.type != 'CLONE';
       
      this.eventInfoForm.patchValue({
        name: changes.eventSelected?.currentValue?.name,
        description: changes.eventSelected?.currentValue?.description,
        date:{ startDate: changes.eventSelected?.currentValue?.date, endDate: changes.eventSelected?.currentValue?.date },
        capacity: changes.eventSelected?.currentValue?.capacity,
        duration: changes.eventSelected?.currentValue?.duration,
        // extLink: changes.eventSelected?.currentValue?.extLink,
        partners: changes.eventSelected?.currentValue?.partners,
        maxPartners: changes.eventSelected?.currentValue?.maxPartners,
      });

      //? Valida switch de invitados al editar o clonar
      if(changes.eventSelected?.currentValue?.partners){
        this.validRequired = true;
        this.eventInfoForm.controls["maxPartners"].setValidators([Validators.required, Validators.min(1)]);
        this.eventInfoForm.controls["maxPartners"].enable();
        this.eventInfoForm.controls["maxPartners"].markAsTouched();
        
      }else{
        this.eventInfoForm.get("maxPartners").disable();
      }
      //? -------------------------------------------->

      this.scheduleId = changes.eventSelected.currentValue?.scheduleId;
      
      this.selectedDate = {startDate:moment(), endDate:moment()};
      this.auxDateSelected = this.selectedDate;
      // this.eventInfoForm.value.partnersthis.modalOptions.type == 'CLONE' ? this.eventInfoForm.get("maxPartners").enable() :  this.eventInfoForm.get("maxPartners").disable();
      
      if(this.modalOptions.type == 'CLONE'){
        this.today = moment();
        this.validHourRanges();

        this.eventInfoForm.patchValue({ 
          date: { 
            startDate: moment(moment().format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A"), 
            endDate: moment(moment().format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A") 
          } 
        });
      }else{
        this.today = moment(changes.eventSelected?.currentValue?.date) < moment() ? moment(changes.eventSelected?.currentValue?.date) : moment();

        this.hourSelectedStart = moment(changes.eventSelected?.currentValue?.date).format("h");
        this.minutesSelectedStart = (Math.round((parseInt(moment(changes.eventSelected?.currentValue?.date).format("mm")) / 5)) * 5) == 60 ? 0 : (Math.round((parseInt(moment(changes.eventSelected?.currentValue?.date).format("mm")) / 5) ) * 5);
        this.momentDaySelectedStart = moment(changes.eventSelected?.currentValue?.date).format("A");
      }

      this.auxhourSelectedStart =  this.hourSelectedStart;
      this.auxminutesSelectedStart = this.minutesSelectedStart;
      this.auxmomentDaySelectedStart = this.momentDaySelectedStart;

      changes.eventSelected?.currentValue?.status != 'FINISHED' ? this.validHourRanges() : "";
    }
  }

  ngOnInit(): void {
    this.eventInfoForm = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      description: [null, Validators.compose([Validators.required])],
      date: [null],
      capacity: [null, Validators.compose([Validators.required, Validators.max(10000), Validators.min(0)])],
      duration: [null, Validators.compose([Validators.required, Validators.min(0)])],
      partners: [false],
      // extLink: [null, Validators.compose([Validators.pattern(this.regex)])],
      maxPartners: [null],
    });

    this.jsonLanguage = this.userService?.user?.jsonLanguage;
    this.userService.user.language == "ESP" ? this.dateOptions = datePicker.locale.spanish : this.dateOptions = datePicker.locale.english;
    
    this.eventInfoForm.get("maxPartners").disable();
    if (this.modalOptions.type == 'EDIT') {
      if (!this.manageEvents) {
        this.eventInfoForm.get('name').disable();
        this.eventInfoForm.get('description').disable();
        this.eventInfoForm.get('date').disable();
        this.eventInfoForm.get('capacity').disable();
        this.eventInfoForm.get('duration').disable();
        this.eventInfoForm.get('partners').disable();
        this.eventInfoForm.get('maxPartners').disable();
      }
    }
    

    this.hourSelectedStart = this.today.format("h");
    this.minutesSelectedStart = (Math.round((parseInt(this.today.format("mm")) / 5) + (0.5)) * 5) == 60 ? 0 : (Math.round((parseInt(this.today.format("mm")) / 5) + (0.5)) * 5);
    this.momentDaySelectedStart = this.today.format("A");

    this.validHourRanges();
  }

  public changeDate(event: any):Promise<boolean>{   
      if (event.startDate) {

        if (this.dateRangeFired) {
          this.dateRangeFired = false;
          return;
        }
        this.dateRangeFired = true;

        this.changeDateValidation('date');
      }
  }

  public validIsNumeric(event) {
    this.validatorsService.isInteger(event);
  }

  public changeCheckValue(event) {
    
    if (event) {
      this.eventInfoForm.controls["maxPartners"].setValidators([Validators.required, Validators.min(1)]);
      this.eventInfoForm.controls["maxPartners"].enable();
      this.eventInfoForm.controls["maxPartners"].markAsTouched();

      this.validRequired = true;
      
    } else {
      this.eventInfoForm.get("maxPartners").setValue(null);
      this.eventInfoForm.get('maxPartners').clearValidators();
      this.eventInfoForm.get("maxPartners").disable();
      this.validRequired = false;

    }
  }

  public async NextStep() {
    this.eventInfoForm.markAllAsTouched()
    const isValid = this.eventInfoForm.valid
    if (isValid) this.onNext.emit({ data: this.eventInfoForm.value, type: 'INFO' }) 
  }

  public close(){
    this.onClose.emit();
  }

  public async validHourRanges() {

    this.eventInfoForm.patchValue({ 
      date: { 
        startDate: moment(moment(this.selectedDate.startDate).format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A"), 
        endDate: moment(moment(this.selectedDate.startDate).format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A") 
      } 
    });
    if (moment(moment(this.selectedDate.startDate).format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A").format("YYYY-MM-DDTHH:mm") 
        < this.today.format("YYYY-MM-DDTHH:mm")
    ) {
      this.hourSelectedStart = this.today.format("h");
      this.minutesSelectedStart = (Math.round((parseInt(this.today.format("mm")) / 5) + (0.5)) * 5) == 60 ? 0 : (Math.round((parseInt(this.today.format("mm")) / 5) + (0.5)) * 5);
      this.momentDaySelectedStart = this.today.format("A");
    };

    this.hoursStart = this.hours?.filter(hour => { return moment(moment(this.selectedDate.startDate).format("DD-MM-YYYY") + " " + hour.value + " " + this.momentDaySelectedStart, "DD-MM-YYYY h A").format("YYYY-MM-DDTHH") >= this.today.format("YYYY-MM-DDTHH") });
    this.minutesStart = this.minutes.filter(min => { return moment(moment(this.selectedDate.startDate).format("DD-MM-YYYY") + " " + this.hourSelectedStart + " " + min.label + " " + this.momentDaySelectedStart, "DD-MM-YYYY h mm A").format("YYYY-MM-DDTHH:mm") >= this.today.format("YYYY-MM-DDTHH:mm") });
    this.momentdayStart = this.momentDay.filter(type => { return moment(moment(this.selectedDate.startDate).format("DD-MM-YYYY") + " " + this.hourSelectedStart + " " + this.minutesSelectedStart + " " + type?.value, "DD-MM-YYYY h mm A").format("YYYY-MM-DDTHH:mm") >= this.today.format("YYYY-MM-DDTHH:mm") });

    if (!this.minutesStart.length) {
      this.minutesStart = [
        { value: 0, label: "00" },
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" },
        { value: 40, label: "40" },
        { value: 50, label: "50" }
      ];
      if (this.hourSelectedStart == "11" || this.hourSelectedStart == "23") {
        this.momentdayStart = this.momentDay.filter(type => {return type?.value != this.momentDaySelectedStart});
        this.momentDaySelectedStart = this.momentDay.find(type => {return type?.value != this.momentDaySelectedStart})?.value;
      }
      this.hoursStart = this.hours?.filter(hour => { return moment(moment(this.selectedDate.startDate).format("DD-MM-YYYY") + " " + hour.value + ":00 " + this.momentDaySelectedStart, "DD-MM-YYYY h:mm A").format("YYYY-MM-DDTHH:mm") >= this.today.format("YYYY-MM-DDTHH:mm") });
      this.hourSelectedStart = this.hoursStart[0].value;
      
    }

  }

  public async changeDateValidation(type:string){

    if (this.scheduleId && this.countActivities > 0 && this.modalOptions.type != 'CLONE') {

      Swal.fire({
        title: '¿Desea modificar la fecha del evento?',
        text: 'Al modificar la fecha u hora puede que no coincidan con las actividades en la agenda.',
        icon: 'info',
        showDenyButton: true,
        confirmButtonText: 'Cambiar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {

          if (type == 'date') {
            this.eventInfoForm.patchValue({ 
              date: { 
                startDate: moment(moment(this.selectedDate.startDate).format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A"), 
                endDate: moment(moment(this.selectedDate.startDate).format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A") 
              } 
            });
            this.validHourRanges();
          }else{
            this.validHourRanges()
          }
        
        }else{
          if (type == 'date') {
            this.selectedDate = this.auxDateSelected;
          }else{
            this.hourSelectedStart = this.auxhourSelectedStart;
            this.minutesSelectedStart = this.auxminutesSelectedStart;
            this.momentDaySelectedStart = this.auxmomentDaySelectedStart;
          }   
        }
      });
    }else{
      this.dateRangeFired = true;
      this.eventInfoForm.patchValue({ 
        date: { 
          startDate: moment(moment(this.selectedDate.startDate).format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A"), 
          endDate: moment(moment(this.selectedDate.startDate).format("YYYY-MM-DD") + "T" + this.hourSelectedStart + ':' + this.minutesSelectedStart + ' ' + this.momentDaySelectedStart, "YYYY-MM-DDTh:mm A") 
        } 
      });
      this.validHourRanges();
    }
  }
}
