import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../shared/services/http.service';
import { environment } from '../../environments/environment';
import { CryptoSharkService } from '../shared/services/crypto-shark.service';
import { UserService } from '../shared/services/user.service';
import { SocketService } from '../shared/services/socket.service';
import { ILogin } from '../interfaces/ilogin';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../shared/services/alert.service';
import { LanguageService } from '../shared/services/http/language.service';
import * as moment from 'moment';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})

export class AuthenticationComponent implements OnInit {
  
  public idUser = null;
  public userToken = null;
  public languages = [{code: 'mx', name: 'Español'},{code: 'us', name: 'English'},{code: 'ht', name: 'kreyòl'}];
  public selectedLanguage: any = {};
  public jsonLanguage: any = {};
  @ViewChild("modalCredentials") modalCredentials: NgbModal;

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public loginData: ILogin = {};
  public returnUrl: string
  public expiredSesionAlert: boolean;
  public invalidCredentials: boolean;
  public modal: any;
  public mfa = [null, null, null, null, null, null];
  public envData: string = "";
  public envKey: string = "";
  public companyData = null;

  public get = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.urlAdmin}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});
  
  constructor(

    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private cryptoService: CryptoSharkService,
    private userService: UserService,
    private socketService: SocketService,
    private http: HttpClient,
    public modalService: NgbModal,
    private toast: AlertService,
    public languageService: LanguageService

  ) { }

   ngOnInit() {

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.getSubdomain();

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      nip: ['', Validators.required]
    });

     this.get(`api/login/company/${environment.domainadmin}`).subscribe((response) => {
      
      this.loginData.company = response.data;
      environment.apiUrl = this.loginData.company?.server;
      environment.socketUrl = this.loginData.company?.server;

      //* LENGUAJE DEFAULT DE EVA
      this.userService.user.languages = this.loginData?.company?.languages || [];
      this.userService.user.jsonLanguage =  this.loginData?.company?.jsonLanguage;
      this.jsonLanguage = this.loginData?.company?.jsonLanguage;
      this.selectedLanguage = this.loginData?.company?.languages.find(item => {return this.loginData?.company?.defaultLanguage == item?.name});
      moment.locale(this.userService.user.languages == "ESP" ? 'ES' : this.userService.user.languages == "ENG" ? 'EN' : 'ES'); 
      //*====================

      this.httpService.post(`api/company/language`, {
        data: this.loginData.company.data,
        key: this.loginData.company.key,
        company: this.cryptoService.encryptAes(
          environment.subdomain,
          this.loginData.company.key
        )}
      ).toPromise().then((response: any) => {
        let lenguajeList = response?.languages || [{file: "es.json", name: "ESP", longname: "Español", code: "mx"}];
        
        let language = lenguajeList?.find(item => { return response?.defaultLanguage == item?.name }) || {file: "es.json", name: "ESP", longname: "Español", code: "mx"};
        this.loginData.company.languages = lenguajeList;
        this.changeLanguage(language);
      }).catch((e) => { console.log(e); });

    });

  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  /**
   * Obtener el dominio del cliente
   * @returns Dominio del cliente
   */
  getSubdomain() {

    const domain = window.location.hostname;

    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === '45'
    ) {
      environment.subdomain = 'beta';
    } else if (domain.split('.')[0] === 'demoeventos') {
      environment.subdomain = "demo";
    }
    else if (domain.split('.')[0] === 'eventospruebas') {
      environment.subdomain = "pruebas";
    }
    else {
      environment.subdomain = domain.split('.')[0];
    }
   }

  async getCompanyData() {
    try {

    if (this.companyData === null) {

      await this.get(`api/login/company/${environment.domainadmin}`).toPromise()
      .then((response: any) => {

        this.companyData = response.data;
        this.companyData.domainadmin = environment.domainadmin;
        this.companyData.subdomain = environment.subdomain;

        if (this.envData === "" || this.envKey === "") {
          this.envData = response?.data?.data;
          this.envKey = response?.data?.key;
          environment.apiUrl = response?.data.server;
        }
      })
      .catch((e) => { console.log(e) })
    
    }
      
    } catch (error) {
      console.log(error);
    }
    
  }


  async Submit() {
    try {
      
      this.httpService.post(`api/login`, {
        data: this.loginData.company.data,
        login: this.cryptoService.encryptAes((this.loginForm.controls['username'].value).trim(), this.loginData.company.key),
        key: this.loginData.company.key,
        password: this.cryptoService.encryptSha(this.loginForm.controls['password'].value),
        nip: this.cryptoService.encryptSha(this.loginForm.controls['nip'].value),
        company: this.cryptoService.encryptAes(
          environment.subdomain, 
          this.loginData.company.key
        ),
        defaultLanguage:  this.selectedLanguage?.name || null,
        jsonLanguage: JSON.stringify(this.jsonLanguage) || null

      }).toPromise().then((response: any) => {

        if (response.isRegistered) {
          this.loginData.token = response.token;
          this.userService.signInCurrentUser(this.loginData);
          this.router.navigateByUrl(this.returnUrl);
          
        }
        else {
          this.userToken = response.token;
          this.openModal();
        }

      }).catch((e) => { this.invalidCredentials = e.error.message ? true : false })



    } catch (error) {
      console.log(error);
    }

  }

  checkMFA(event: any) {
    let element;
    if (event.code !== 'Backspace') element = event.srcElement.nextElementSibling;
    if (event.code === 'Backspace') element = event.srcElement.previousElementSibling;
    if (element == null) return; else element.focus();
  }

  async openModal() {

    this.modal = this.modalService.open(this.modalCredentials, { size: 'lg', backdrop: 'static' });

  }

  async openModalRecovery(template: any) {

    if (this.companyData === null) {

      await this.getCompanyData();
      
      setTimeout(async () => { this.modalService.open(template, { size: 'md', backdrop: 'static' }) }, 400);
    }
    else this.modalService.open(template, { size: 'md', backdrop: 'static' });

  }

  reset() {
    this.idUser = null;
    this.userToken = null;
    this.loginForm.reset();
  }

  public changeLanguage(language){
    try {
      if (language) {
        this.languageService.getLanguage(language?.file).then((response) => {
          if (response) {
            this.userService.user.jsonLanguage = response || [];
            this.jsonLanguage = response;
            this.selectedLanguage = language;   
            moment.locale(language.name == "ESP" ? 'ES' : language.name == "ENG" ? 'EN' : 'ES');
          }
        }).catch((e) => {
          console.log(e);
        });
      }
      
    } catch (error) {
     console.log(error);
    }
  }
  
}

